import { useTrackViewedLoginPage } from '@air/analytics';
import { config } from '@air/api';
import { useToasts } from '@air/provider-toast';
import { getIsSAMLEnforced } from '@air/utils-auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isString } from 'lodash';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useMount } from 'react-use';

import { LoginForm } from '~/components/Auth/LoginForm';
import { DividerText } from '~/components/DividerText';
import { ExpiredSessionBanner } from '~/components/ExpiredSessionBanner/ExpiredSessionBanner';
import {
  disableExpiredSessionBanner,
  isExpiredSessionBannerEnabled,
} from '~/components/ExpiredSessionBanner/ExpiredSessionBannerUtils';
import { getLayout } from '~/components/Layouts/AppProviderLayout';
import { AuthLayout } from '~/components/Layouts/AuthLayout';
import { MaintenanceModeMessage } from '~/components/MaintenanceMode/MaintenanceModeMessage';
import { SSOButtons } from '~/components/SSOButtons';
import { I18n } from '~/constants/I18n';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { FORGOT_PASSWORD } from '~/constants/testIDs';
import { useHandlePostAuthentication } from '~/hooks/useHandlePostAuthentication';
import { useLogin } from '~/swr-hooks/auth/useLogin';
import { redirectURIKey, SAML_SSO_ENFORCED } from '~/utils/SessionHelpers';
import { wipePersistedCognitoArtifacts } from '~/utils/wipePersistedCognitoArtifacts';

export default function Login() {
  const [title, setTitle] = useState('Welcome back!');
  const { loginUser, showLoginError } = useLogin();
  const { maintenanceModeJson } = useFlags();
  const { showToast } = useToasts();
  const [redirectUrl, setRedirectUrl] = useState('');
  const { query } = useRouter();
  const passwordReset = isString(query.passwordReset);
  const { trackViewedLoginPage } = useTrackViewedLoginPage();
  const { handlePostAuthentication } = useHandlePostAuthentication();
  const [showExpiredSessionBanner, setShowExpiredSessionBanner] = useState(false);

  const {
    query: { existingEmail },
  } = useRouter();

  useMount(() => {
    trackViewedLoginPage();
    const authConfig = config.getAuthConfiguration();
    wipePersistedCognitoArtifacts(authConfig.oauth.clientId);
  });

  useMount(() => {
    if (isExpiredSessionBannerEnabled()) {
      disableExpiredSessionBanner();
      setShowExpiredSessionBanner(true);
    }
  });

  useEffect(() => {
    const showExistingEmailMessage = () => {
      if (existingEmail) {
        showToast('Looks like you already have an account with us! Please log in.', {
          timeBeforeAutoDismiss: undefined,
          type: 'assertive',
          withCloseButton: true,
        });
      }
    };

    /* All toasts are cleared on "routeChangeComplete" event.
    Since we are registering this toast on page load, it gets cleared as well.
    We need to use timeout to avoid this */
    setTimeout(showExistingEmailMessage, 1000);
  }, [showToast, existingEmail]);

  useEffect(() => {
    /** Clear key in case user has clicked around before loggin in or signing up
     * and it doesn't get cleared where expected */
    const showSamlErrorToast = sessionStorage.getItem(SAML_SSO_ENFORCED);
    if (showSamlErrorToast) {
      showToast('You must sign in with SAML SSO for this account', {
        timeBeforeAutoDismiss: undefined,
        type: 'assertive',
        withCloseButton: true,
      });
    }
    sessionStorage.removeItem(SAML_SSO_ENFORCED);

    setRedirectUrl(Router.query.redirect as string);

    const redirectURIFromLogoutPage = window.sessionStorage.getItem(redirectURIKey);

    if (redirectURIFromLogoutPage) {
      window.sessionStorage.removeItem(redirectURIKey);
      Router.replace(decodeURIComponent(redirectURIFromLogoutPage));
    } else {
      Router.prefetch(Routes.home);

      if (!!passwordReset) {
        setTitle('Password was reset!');
      }
    }
  }, [showToast, passwordReset]);

  const loginWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      // first ensure they aren't in a SAML-enforced workspace
      // if so, we log them out and tell them they have to sign in with SAML SSO
      const isSAMLEnforced = await getIsSAMLEnforced(email);

      if (isSAMLEnforced) {
        showToast('You must sign in with SAML SSO for this account', {
          timeBeforeAutoDismiss: undefined,
          type: 'assertive',
          withCloseButton: true,
        });
      } else {
        await loginUser(email, password);

        const redirect = isString(Router.query.redirect) ? decodeURIComponent(Router.query.redirect) : undefined;

        await handlePostAuthentication({ email, redirect });
      }
    },
    [handlePostAuthentication, loginUser, showToast],
  );

  return (
    <AuthLayout
      layout={{
        visibleTitleText: title,
        nextSeoProps: { title: I18n.loginPageTitle, description: I18n.loginPageDescription },
      }}
      banner={!!showExpiredSessionBanner && <ExpiredSessionBanner />}
    >
      <div>
        <MaintenanceModeMessage />
        <SSOButtons
          ssoCallbackData={!!redirectUrl ? { url: redirectUrl, action: { action: 'oauth-redirect' } } : undefined}
          disabled={maintenanceModeJson?.maintenanceMessage}
          verbText="Log in"
          className="mt-6"
          customState={{
            creationSource: 'main',
          }}
        />

        <DividerText />

        <LoginForm
          disabled={maintenanceModeJson?.maintenanceMessage}
          loginUser={loginWithEmailAndPassword}
          onError={showLoginError}
          existingEmail={isString(existingEmail) ? decodeURIComponent(existingEmail) : undefined}
        />

        {!maintenanceModeJson?.maintenanceMessage && (
          <div>
            <Link className="mt-3 inline-block text-14" data-testid={FORGOT_PASSWORD} href={Routes.auth.forgot}>
              Forgot password
            </Link>

            <div className="mt-6 text-center text-14 text-grey-11">
              New to Air?
              <br />
              <Link
                className="text-14 font-semibold"
                href={
                  !!redirectUrl
                    ? `${Routes.auth.signUp}?${QueryParamNames.redirect}=${encodeURIComponent(redirectUrl)}`
                    : Routes.auth.signUp
                }
              >
                Sign up
              </Link>
            </div>
          </div>
        )}
      </div>
    </AuthLayout>
  );
}

Login.getLayout = getLayout;
