import { Auth } from '@air/api';
import { Button } from '@air/primitive-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { callOnFullAccountUserPool } from '@air/utils-auth';
import Router from 'next/router';

import { ContinueWithSAMLSSOButton } from '~/components/ContinueWithSAMLSSOButton';
import { saveSSOCallbackData, SSOCallbackData } from '~/components/PublicSSOCallback/utils';
import { Routes } from '~/constants/routes';
import { APPLE_SIGN_IN_BUTTON, GOOGLE_SIGN_IN_BUTTON } from '~/constants/testIDs';

const AppleLogo = ({ className }: { className: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="none"
    aria-hidden="true"
  >
    <path
      d="M11.94 3.19C12.3181 2.75428 12.6063 2.24817 12.7882 1.70074C12.9701 1.1533 13.0421 0.575328 13 0C11.8394 0.093687 10.762 0.639556 10 1.52C9.63513 1.94198 9.35861 2.43288 9.18684 2.96364C9.01507 3.49441 8.95155 4.05424 9.00003 4.61C9.56618 4.61472 10.1258 4.4891 10.6356 4.24286C11.1454 3.99662 11.5918 3.63637 11.94 3.19ZM14.46 10.63C14.4667 9.86374 14.6686 9.11182 15.0465 8.4452C15.4244 7.77859 15.966 7.21926 16.62 6.82C16.2073 6.22524 15.6616 5.73483 15.0263 5.38767C14.391 5.04052 13.6835 4.84615 12.96 4.82C11.4 4.66 9.96003 5.73 9.13003 5.73C8.30003 5.73 7.13003 4.84 5.83003 4.86C4.98016 4.888 4.15205 5.13578 3.42648 5.57919C2.70091 6.02259 2.10264 6.64648 1.69003 7.39C-0.0699663 10.45 1.24003 15 3.00003 17.47C3.80003 18.68 4.80003 20.05 6.12003 20C7.44003 19.95 7.87003 19.18 9.40003 19.18C10.93 19.18 11.4 20 12.7 19.97C14 19.94 14.92 18.73 15.76 17.52C16.3551 16.6415 16.8199 15.6816 17.14 14.67C16.3475 14.332 15.6714 13.7693 15.1951 13.0513C14.7188 12.3333 14.4633 11.4916 14.46 10.63Z"
      fill="black"
    />
  </svg>
);

export const GoogleLogo = ({ className }: { className: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="none"
    aria-hidden="true"
  >
    <path
      d="M6.99023 13.8774L6.36445 16.2153L4.07578 16.2645C3.38672 14.9953 3 13.5434 3 12C3 10.5059 3.36211 9.09964 4.00547 7.86214L6.04453 8.23479L6.9375 10.2598C6.75117 10.8047 6.64922 11.3883 6.64922 12C6.64922 12.661 6.76875 13.2938 6.99023 13.8774Z"
      fill="#FBBB00"
    />
    <path
      d="M20.8418 10.3195C20.9438 10.8644 21 11.4269 21 12C21 12.6433 20.9332 13.2726 20.8032 13.8808C20.3637 15.9445 19.2211 17.7445 17.6356 19.0207L15.0692 18.8906L14.7071 16.623C15.7582 16.0043 16.5809 15.041 17.0133 13.8844H12.1969V10.3195H17.0801H20.8418Z"
      fill="#518EF8"
    />
    <path
      d="M17.6355 19.0173C16.0922 20.2583 14.134 21.0001 12 21.0001C8.57227 21.0001 5.59102 19.0841 4.07227 16.2645L6.99023 13.8774C7.74961 15.9059 9.70781 17.3509 12 17.3509C12.9844 17.3509 13.909 17.0837 14.7035 16.6196L17.6355 19.0173Z"
      fill="#28B446"
    />
    <path
      d="M17.7446 5.0707L14.8301 7.45781C14.011 6.94453 13.0407 6.64922 12 6.64922C9.6551 6.64922 7.66174 8.16094 6.93752 10.2598L4.00549 7.85859C5.50315 4.97227 8.52307 3 12 3C14.1832 3 16.1871 3.77695 17.7446 5.0707Z"
      fill="#F14336"
    />
  </svg>
);

export interface SSOButtonsProps {
  /**
   * @description The text that precedes "with SSO Provider" for each button
   * @default "Log in"
   * @example "Log in" or "Sign up"
   */
  verbText?: string;
  ssoCallbackData?: SSOCallbackData;
  className?: string;
  onContinueWithSamlClick?: () => void;
  disabled?: boolean;
  customState: object | undefined;
}

export const SSOButtons = ({
  ssoCallbackData,
  verbText = 'Log in',
  disabled,
  onContinueWithSamlClick,
  customState,
  className,
}: SSOButtonsProps) => {
  const saveRedirectData = () => {
    // save data about action to perform when user signs in using SSO
    if (ssoCallbackData) {
      saveSSOCallbackData(ssoCallbackData);
    }
  };

  return (
    <div className={tailwindMerge('flex w-full flex-col items-center gap-2', className)}>
      <Button
        appearance="outline"
        color="grey"
        size="large"
        className="w-full"
        data-testid={GOOGLE_SIGN_IN_BUTTON}
        onClick={() => {
          saveRedirectData();
          callOnFullAccountUserPool(() => Auth.googleSignIn(customState));
        }}
        prefix={<GoogleLogo className="size-4" />}
        disabled={disabled}
      >
        <p className="text-grey-12">{verbText} with Google</p>
      </Button>

      <Button
        appearance="outline"
        color="grey"
        size="large"
        className="w-full"
        data-testid={APPLE_SIGN_IN_BUTTON}
        onClick={() => {
          saveRedirectData();
          callOnFullAccountUserPool(() => Auth.appleSignIn(customState));
        }}
        prefix={<AppleLogo className="size-4" />}
        disabled={disabled}
      >
        <p className="text-grey-12">{verbText} with Apple</p>
      </Button>

      <ContinueWithSAMLSSOButton
        className="mt-4"
        onClick={() => {
          saveRedirectData();

          if (onContinueWithSamlClick) {
            onContinueWithSamlClick();
          } else {
            Router.push({
              pathname: Routes.auth.samlSSOLogin,
              query: {
                customState: customState ? JSON.stringify(customState) : undefined,
              },
            });
          }
        }}
      />
    </div>
  );
};
