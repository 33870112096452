import { useFlags } from 'launchdarkly-react-client-sdk';

export const MaintenanceModeMessage = () => {
  const { maintenanceModeJson } = useFlags();

  if (maintenanceModeJson?.maintenanceMessage) {
    return (
      <div className="bg-canary-50 p-4">
        <div className="text-14 text-canary-900">{maintenanceModeJson.maintenanceMessage}</div>
      </div>
    );
  }

  return null;
};
