import { Lock } from '@air/next-icons';
import { Banner } from '@air/primitive-banner';

export const ExpiredSessionBanner = () => {
  return (
    <div className="mb-10 flex justify-center">
      <Banner appearance="warning">
        <Lock className="mr-2 inline w-4" />
        Your session has expired. Please re-authenticate below.
      </Banner>
    </div>
  );
};
